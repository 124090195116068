import ajax from '../vendors/ajax';
import PureMask from '../vendors/pureMask';
import VMasker from '../vendors/maskMoney';

//
// Preview Images Upload
//
function preview_image() {
  var files = Array.from(document.querySelectorAll('.js-filepreview'));
  var reader = new FileReader();

  var preview = function(elem) {
    let index = elem.getAttribute('data-photo');
    let parent = document.querySelector('[data-photo="'+index+'"]');
    let preview_wrapper = parent.querySelector('.preview_wrapper');
    let upload_wrapper = parent.querySelector('.upload_wrapper');

    reader.onload = function(){
      //console.log(preview_wrapper);
      //console.log(upload_wrapper);
      //console.log(output);
      preview_wrapper.querySelector('img').src = reader.result;
      preview_wrapper.removeAttribute('hidden');
      upload_wrapper.setAttribute('hidden', true);
    }
    reader.readAsDataURL(event.target.files[0]);
  };


  files.forEach((file) => {
    file.addEventListener('change', () => {preview(file)});
  });
}

// Remove Imagens carregadas para preview
function remove_image_preview() {
  var removes = document.querySelectorAll('.js-remove-photo');
  var removeImg = function(evt) {
    evt.preventDefault();
    var parent = this.parentElement.parentElement;
    //console.log(evt.target.parentElement.parentElement);
    var preview_wrapper = parent.querySelector('.preview_wrapper');
    var upload_wrapper = parent.querySelector('.upload_wrapper');
    var image_exist;
    //console.log(preview_wrapper);

    // remove image
    preview_wrapper.querySelector('img').src = '';
    var img_salva = preview_wrapper.querySelector('input[name="photos_salvas[]"]');
    if(img_salva)
      img_salva.remove();
    upload_wrapper.querySelector('input[type="file"]').value  = '';

    preview_wrapper.setAttribute('hidden', true);
    upload_wrapper.removeAttribute('hidden');

  };

  for (var i = 0; i < removes.length; i++) {
    removes[i].addEventListener('click', removeImg);
  }

}

// Create function serialize;
HTMLElement.prototype.serialize = function(type){
  var obj = {};
  var array = [];
  var arrayCheckbox = [];
  var elements = this.querySelectorAll( "input, select, textarea" );
  for( var i = 0; i < elements.length; ++i ) {
    var element = elements[i],
      name, value;
    if(element.type == 'checkbox' && !element.checked ) {
      name = null;
      value = null;
    } else {
      name = element.name;
      value = element.value;
    }
    // Fix error parser_str remove parameter
    if(element.name.indexOf('link') !== -1) {
      value = (value.indexOf('&') !== -1) ? value.replace("&", "€") : value;
    }


    if( name ) {
      (type === 'object') ?  obj[ name ] = value : array.push(name + '=' + value);
    }
  }
  return (type === 'object') ? JSON.stringify( obj ) : array.join('&');
}


function showPassword() {
  let togglePassVisible;
  if( document.querySelector('.js-show-pass')) {
    var targets = Array.from(document.querySelectorAll('.js-show-pass'));
    targets.forEach(function(target) {
      target.addEventListener('click', function(evt) {
        evt.preventDefault();
        this.classList.toggle('is-show');
        var passwordInput = this.parentNode.querySelector('input');
        if(this.classList.contains('is-show')) {
          passwordInput.type = 'text';
        } else {
          passwordInput.type = 'password';
        }
      });
    });
  }
}

// Formularios Ajax
function formulario() {
  var forms = document.querySelectorAll('.js-form-ajax'),
    form, form_action, form_data, form_button;


  var statusButton = function(btn, sending, disabled) {
    var txtButton = (sending === true ) ? 'Enviando ...' : btn.dataset.text;
    (disabled === true) ? btn.setAttribute('disabled', 'disabled') : btn.removeAttribute('disabled');
    btn.innerHTML = txtButton;
  };

  var alertBox = function(msg, state, trigger) {
    let alertboxContainer = (typeof trigger != 'undefined') ?  document.querySelector(trigger) : document.querySelector('.js-alert-form');
    let timeOut = (alertboxContainer.hasAttribute('data-timeout')) ? alertboxContainer.dataset.timeout : 10000;
    if(alertboxContainer !== null) {
      var top = alertboxContainer.getBoundingClientRect().top;
      if(typeof msg === 'object') {
        var html = '';
        for(var key in msg) {
          html += msg[key] + '<br>';
        }
        alertboxContainer.innerHTML = (timeOut != 'false' ) ? html + ' <br><br> <small> Essa mensagem fechará em alguns segundos.</small>' : html;
      } else {
        alertboxContainer.innerHTML = (timeOut != 'false' ) ? msg + ' <br><br> <small> Essa mensagem fechará em alguns segundos. </small>' : msg;
      }
      alertboxContainer.classList.remove('c-alert--warning', 'c-alert--success', 'c-alert--danger', 'c-alert--info');
      alertboxContainer.classList.add('c-alert--'+state, 'show');
      alertboxContainer.removeAttribute('hidden');
      if(alertboxContainer.hasAttribute('aria-disabled')) {
        alertboxContainer.dataset.ariaDisabled = 'false';
      }

      if(timeOut != 'false')
        setTimeout(function(){ alertboxContainer.classList.remove('c-alert--'+state, 'show'); }, timeOut);
      //window.scrollTo(top, 0);
    }
  };

  var uploadFiles = function(form, formData) {
    var files = form.querySelectorAll('input[type="file"]');
    // Loop through each of the selected files.
    for (var i = 0; i < files.length; i++) {
      let nameArray = files[i].name;
      console.log(nameArray);
      var file = files[i].files;
      // Check the file type.
      if (file.length > 0) {

        if(file[0].type.match('image.*')) {
          //alert('photo');
          formData.append(nameArray, file[0], file[0].name);
          //console.log('photos[],' + file[0] + ' , ' + file[0].name);
        }
      }
    }
  };

  var submit = function(e) {
    e.preventDefault();
    form_action = (this.getAttribute('action')) ? this.getAttribute('action') : 'submit_ajax';
    //console.log(form_action);
    var formData = new FormData();
    formData.append('action', form_action);
    form_button = this.querySelector('button[type="submit"]');
    form = this;
    statusButton(form_button, true, true);
    // check upload
    if(form.getAttribute('enctype') === "multipart/form-data") {
      uploadFiles(form, formData);
    }

    if(form_action) {
      form = this;
      //setTimeout(function(){
      //console.log(form);
      formData.append('dados',  form.serialize() );
      var request = ajax({
        baseUrl: api.ajax,
        headers: { 'content-type': null }
      });

      let formAlert = (form.dataset.alert != 'undefined') ? form.dataset.alert : undefined;
      console.log(formAlert);

      request.post('', formData).then(function (response, xhr) {
        if(response.success === true) {
          console.log(form.dataset);
          console.log(formAlert);
          alertBox(response.message, 'success', formAlert);
          if(response.redirect === true) {
            if(response.timeout) {
              window.setTimeout( function() {
                location.reload();
              }, 5000 );
            } else {
              location.reload();
            }
          } else if(typeof response.redirect === 'string') {
            window.setTimeout( function() {
              window.location = response.redirect;
            }, 5000 );
            statusButton(form_button, false, true);
          } else {
            statusButton(form_button, false, false);
          }

        } else {
          //console.log(response.message);
          alertBox(response.message, 'warning', formAlert);
          statusButton(form_button, false, false);
        }
      }).catch(function (response, xhr) {
        console.log(response);
        alertBox(response.message, 'warning', formAlert);
        statusButton(form_button, false, false);
      })

    } else {
      console.warn('Não existe nenhuma ação !!!');
      statusButton(form_button, false, false);
    }
  };
  // Add Events Submit
  for (var i = 0; i < forms.length; i++) {
    forms[i].addEventListener('submit', submit);
  }
}

function initForm() {
  formulario();
  showPassword();
  //categorias_anuncio();
  //changeRadioPessoa();
  preview_image();
  remove_image_preview();
  //toggleCheckInput();
  //PureMask.format(".telefone", true);
  PureMask.format(".js-mask", false);
  PureMask.format(".js-mask-wp", false);

  VMasker(document.querySelectorAll(".js-money")).maskMoney({
    // Decimal precision -> "90"
    precision: 2,
    // Decimal separator -> ",90"
    separator: ',',
    // Number delimiter -> "12.345.678"
    delimiter: '.',
    // Money unit -> "R$ 12.345.678,90"
    unit: 'R$',
    // Money unit -> "12.345.678,90 R$"
    //suffixUnit: 'R$',
    // Force type only number instead decimal,
    // masking decimals with ",00"
    // Zero cents -> "R$ 1.234.567.890,00"
    zeroCents: true
  });
  //togglePagamentos();
  //autoCompleteLojas();
}

export {initForm};
