import LazyLoad from './vendors/lazy-ie';
import {tns} from './vendors/tiny-slider';
import SmoothScroll from './vendors/scroll';
import {initForm} from './forms/form';

var app = new MyApp('app');

function MyApp(objId) {
  this.init = function() {
    init();
  };
  this.run = function() {
    if (!document || !document.body || !window[objId]) {
      window.setTimeout(objId + '.run();', 100);
      return;
    }
    this.init();
  };
  this.run();
}

function init() {
  WebFont.load({
    google: {
      families: ['Roboto:400,600,700']
    }
  });
  var lazyLoadInstance = new LazyLoad({
    elements_selector: '.lazy'
  });
  //add_fixed_in_element('.c-main-header__middle', 'is-fixed', '.c-main-header');
  toggleOffCanvas();
  slides();
  removeHash();
  initForm();
  dropdownMenu('.js-menu');
  dropdownMenu('.js-menu-mobile');

  var header =  document.querySelector('#main-header');
  var scroll = new SmoothScroll('a[href*="#"]', {
    offset: function () {
      return header.getBoundingClientRect().height;
    }
  });

}

function toggleOffCanvas() {
  var toggleClass = function(element, $class_add, $class_remove) {
    element.classList.add($class_add);
    element.classList.remove($class_remove);
  };

  let fixedElement = function(element, add = true, $class = 'is-fixed') {
    if(!element)
      return;

    (add == true) ? element.classList.add($class) : element.classList.remove($class)
  };

  let toggleArea = function(element, trigger) {
    if (element.classList.contains('open')) {
      element.classList.remove('open')
      trigger.dataset.canvasOpen = 'false';
    } else {
      element.classList.add('open')
      trigger.dataset.canvasOpen = 'true';
    }

    if(element.classList.contains('hidden')) {
      element.classList.remove('hidden');
    }
    //element.classList.contains('open') ? element.classList.remove('open') : element.classList.add('open');
  };
  var triggers = document.querySelectorAll('.js-offcanvas');
  var icon, iconClose, hasCanvasOpenned, hasCanvasOpennedIcon, addFixedEelement, elementFixed = false;
  triggers.forEach(function(trigger) {
    trigger.addEventListener('click', function(e) {
      e.preventDefault();
      let offcanvas_area = document.querySelector('.offcanvas--' + this.dataset.canvas);
      addFixedEelement = (trigger.dataset.fixed) ? true : false;
      if(addFixedEelement)
        elementFixed = document.querySelector(trigger.dataset.fixed);

      // Check Has Canvas Openned
      hasCanvasOpenned = document.querySelector('.offcanvas.open');
      hasCanvasOpennedIcon = document.querySelector('.js-offcanvas[data-canvas-open="true"]');
      if (hasCanvasOpenned && hasCanvasOpennedIcon && (hasCanvasOpennedIcon.dataset.canvas != this.dataset.canvas)) {
        hasCanvasOpenned.classList.remove('open');
        iconClose = hasCanvasOpennedIcon.querySelector('.show');
        toggleClass(iconClose, 'hidden', 'show');
        icon = hasCanvasOpennedIcon.querySelector('.hidden');
        toggleClass(icon, 'show', 'hidden');
        hasCanvasOpennedIcon.dataset.canvasOpen = 'false';
      }
      // end check
      icon = this.querySelector('.show');
      iconClose = this.querySelector('.hidden')
      toggleClass(icon, 'hidden', 'show');
      toggleClass(iconClose, 'show', 'hidden');
      toggleArea(offcanvas_area, this);
      // Add Class no body
      hasCanvasOpenned = document.querySelector('.offcanvas.open');
      if (hasCanvasOpenned) {
        overflowInBody('add', true);
        fixedElement(elementFixed);
      } else {
        overflowInBody('remove', true);
        fixedElement(elementFixed, false);
      }
      // Fixed element ?
    });
  });
}

function slides() {


  var carousel = '.js-slide-gallery';
  if (document.querySelector(carousel) && document.querySelector(carousel).childElementCount >= 1) {
    var startIndex = (document.querySelector(carousel).childElementCount > 1) ? 1 : 0;
    var slider = tns({
      container: carousel,
      mode: 'carousel',
      items: 1,
      slideBy: 1,
      autoplay: false,
      autoplayTimeout: 1000000,
      autoplayButtonOutput: false,
      arrowKeys: false,
      controls: false,
      mouseDrag: true,
      animateIn: 'fadeIn',
      animateOut: "fadeOut",
      loop: true,
      speed: 500,
      rewind: false,
      navContainer: "#customize-thumbnails",
      navAsThumbnails: true,
      lazyload: true,
    });
  }
}


function overflowInBody(action, addInHtml, myClass) {
  action = action || 'add';
  addInHtml = addInHtml || false;
  myClass = myClass || false;

  if(action == 'add') {
    document.body.classList.add('overflow-hidden', 'relative');
    if(addInHtml == true || addInHtml == 'true')
      document.body.parentNode.classList.add('overflow-hidden', 'relative');
    if(myClass !== false )
      document.body.classList.add(myClass);
  } else {
    document.body.classList.remove('overflow-hidden', 'relative');
    if(addInHtml == true || addInHtml == 'true')
      document.body.parentNode.classList.remove('overflow-hidden', 'relative');
    if(myClass !== false )
      document.body.classList.remove(myClass);
  }
}

function removeHash() {
  var scrollV, scrollH, loc = window.location;
  if ("pushState" in history)
    history.pushState("", document.title, loc.pathname + loc.search);
  else {
    // Prevent scrolling by storing the page's current scroll offset
    scrollV = document.body.scrollTop;
    scrollH = document.body.scrollLeft;

    loc.hash = "";

    // Restore the scroll offset, should be flicker free
    document.body.scrollTop = scrollV;
    document.body.scrollLeft = scrollH;
  }
}


function dropdownMenu(navEl) {

  let nav = document.querySelector(navEl);

  if(nav == null) return false;

  let active;
  let classActive = 'is-active';
  let classDropdown = 'has-children';

  const inactiveDropdown = function() {
    active = nav.querySelector(`.${classActive}`);
    if(active)
      active.classList.remove(classActive);
  };

  const activeDropdown = function(elm) {
    elm.classList.add(classActive);
  };

  let targets = Array.from( nav.querySelectorAll(`.${classDropdown} > a`) );
  targets.forEach(function(target) {
    target.addEventListener('click', function(e) {
      e.preventDefault();
      if(this.parentElement.classList.contains(classActive)) {
        inactiveDropdown();
      } else {
        inactiveDropdown();
        activeDropdown(this.parentElement);
      }

    });
  });

}
